@import 'swiper/swiper-bundle.css';

:root {
  /* 
  --swiper-navigation-size: 44px;
  --swiper-navigation-top-offset: 50%;
  --swiper-navigation-sides-offset: 10px;
  --swiper-navigation-color: var(--swiper-theme-color);
  --swiper-pagination-color: var(--swiper-theme-color);
  --swiper-pagination-left: auto;
  --swiper-pagination-right: 8px;
  --swiper-pagination-bottom: 8px;
  --swiper-pagination-top: auto;
  --swiper-pagination-fraction-color: inherit;
  --swiper-pagination-progressbar-bg-color: rgba(0,0,0,0.25);
  --swiper-pagination-progressbar-size: 4px;
  --swiper-pagination-bullet-size: 8px;
  --swiper-pagination-bullet-width: 8px;
  --swiper-pagination-bullet-height: 8px;
  --swiper-pagination-bullet-border-radius: 50%;
  --swiper-pagination-bullet-inactive-color: #000;
  --swiper-pagination-bullet-inactive-opacity: 0.2;
  --swiper-pagination-bullet-opacity: 1;
  --swiper-pagination-bullet-horizontal-gap: 4px;
  */
  --swiper-theme-color: rgb(174, 29, 34);
  --swiper-navigation-color: white;
  --swiper-navigation-background-color: rgb(174, 29, 34, 0.5);
  --swiper-navigation-height: calc(100% - (var(--swiper-pagination-bullet-size)*2));
  --swiper-navigation-sides-offset: 0;
  --swiper-navigation-top-offset: calc((var(--swiper-navigation-height)/4) - (var(--swiper-navigation-size)*1.4));
  --swiper-pagination-bullet-size: 1rem;
}
/* ==================
  ==  SWIPER STYLES  ==
  ================== */
/* To change all swiper carousel styles use '.swiper' else use an alternate classname */
.swiper .swiper-button-next,
.swiper .swiper-button-prev {
  font-weight: bold;
  visibility: hidden;
  height: 100%;
  padding: 0px 36px;
  top: 0;
  margin-top: 0;
}

.swiper:hover .swiper-button-next,
.swiper:hover .swiper-button-prev {
  visibility: visible;
  background-color: var(--swiper-navigation-background-color);
}

.swiper .swiper-pagination {
  text-align: right;
  padding-right: 1rem;
  display: flex;
  align-content: center;
  justify-content: flex-end;
} 

.swiper.home-hero-carousel .swiper-button-next {
  grid-column: col 12 / span 1;
}

.swiper.home-hero-carousel .swiper-slide {
  background-color: #424242; 
}

.swiper.home-hero-carousel .swiper-slide .slide-image {
  position: relative;
  height: 100%;
  background-position: center;
  background-size: auto 100%;
  background-repeat: no-repeat;
}

