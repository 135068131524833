@import 'animate.css/animate.css';

:root {
  --header-height-mobile: 572px;
  --header-height-desktop: 144px;
  --footer-height-mobile: 1023px;
  --footer-height-tablet: 456px;
  --footer-height-desktop: 325px;
}

.MuiButtonBase-root:disabled {
  cursor: not-allowed;
  pointer-events: auto;
}

.full-height {
  height: calc(100vh - (var(--footer-height-mobile) + var(--header-height-mobile)));
}

@media only screen and (min-width: 900px) {
  .full-height {
    height: calc(100vh - (var(--footer-height-tablet) + var(--header-height-desktop)));
  }
}

@media only screen and (min-width: 1200px) {
  .full-height {
    height: calc(100vh - (var(--footer-height-tablet) + var(--header-height-desktop)));
  }
}
